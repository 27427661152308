import ApplicationController from "./application_controller";

//import TomSelect from 'tom-select/dist/esm/tom-select'
import TomSelect from 'tom-select'
//import 'tom-select/dist/esm/plugins/remove_button/plugin'
//import 'tom-select/dist/esm/plugins/dropdown_input/plugin'
import 'tom-select/dist/css/tom-select.css'
import '../stylesheets/tom-select.scss'

//const selectInput = document.getElementById('applicant_tag_list')

export default class extends ApplicationController {
    static targets = [ "input" ]


    connect() {
        this.initializeTomSelect();
    }

    disconnect() {
        this.destroyTomSelect();
    }


    initializeTomSelect() {
        // Return early if no element is associated with the controller.
        if (!this.element) return;

        const allowCreate = this.inputTarget.dataset.tomselectCreate === 'true'; // Check if the dataset attribute is set to 'true'


        let plugins = {
            //restore_on_backspace: {}
        };
        if (allowCreate) {
            plugins.remove_button = {
                title: 'Entfernen'
            };
        }

        // if placeholder is set, use it, otherwise use the default
        let placeholder = this.inputTarget.dataset.placeholder ? this.inputTarget.dataset.placeholder : "Suchen oder erstellen";


        this.tomselect = new TomSelect(this.inputTarget, {
            plugins: plugins,
            persist: false,
            allowEmptyOption: true,
            create: true, // Set create based on the condition
            createFilter: function(input) {
                input = input.toLowerCase();
                return !(input in this.options);
            },
            placeholder: placeholder, //"Bitte auswählen",
            options: options,
            openOnFocus: true,
            preload: false,
            closeAfterSelect: true,
            selectOnTab: true,
            maxOptions: 100, // limit the number of options
            load: (query, callback) => {
                if (!query.length) return callback();
                fetch(`/backend/tags/query.json?q=${encodeURIComponent(query)}`)
                    .then(response => response.json())
                    .then(data => {
                        callback(data.map(item => ({
                            value: item.name,
                            text: item.name
                        })));
                    }).catch(() => {
                    callback();
                });
            },
            render: {
                no_results: function (data, escape) {
                    return '<div class="no-results">Keine Ergebnisse für "' + escape(data.input) + '". Entfernen mit der ␛-Taste</div>';
                },
                option_create: function(data, escape) {
                    if (allowCreate) {
                        return '<div class="create text-sm"><strong>' + escape(data.input) + '</strong> hinzufügen &hellip;</div>';
                    } else {
                        return '';
                    }
                },
                option: function(data, escape) {
                    return '<div class="option">' +
                        '<span class="title text-sm">' + escape(data.text) + '</span></div>';
                },
                item: function(data, escape) {
                    return '<div class="item text-sm">' + escape(data.text) + '</div>';
                },
                render: {
                    loading_more: function(data, escape) {
                        return `<div class="loading-more-results py-2 d-flex align-items-center"><div class="spinner"></div> Wird geladen</div>`;
                    }
                },
            }

        });

        this.inputTarget.addEventListener('keydown', function(event) {
            if (event.key === 'Backspace') {
                alert('Backspace key pressed');
            }
        });
    }



    destroyTomSelect() {
        this.tomselect.destroy(this.inputTarget)
    }
}